import React from "react";
import Layout from "../components/Layout/layout";
import { Link } from "gatsby";

export default function Index() {
  return (
    <Layout>
      <h1>Welcome to my home on the Internet!</h1>
      <p>
        Hi, I'm Gino Coates, a Software Developer / Architect from Melbourne,
        Australia.
      </p>
      <p>
        While you're here, why don't you read some of my{" "}
        <Link to={"/blog"}>Blog articles</Link>.
      </p>
    </Layout>
  );
}
